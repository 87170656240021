import { memo } from 'react'
import Providers from 'd2/providers/standalone'
import StorageSpaceBanner from './template'
import type { Props } from './types'

export const ComponentWithProviders = memo<Props>(({ d1 }) => (
  <Providers>
    <StorageSpaceBanner d1={d1} />
  </Providers>
))

ComponentWithProviders.displayName = 'StorageSpaceBanner'

export { default } from './template'
