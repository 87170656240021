import { defer } from 'lodash-es'
import { memo, useEffect } from 'react'
import { scrollToTop } from 'd2/utils/ScrollTo'
import { useLocation } from 'd2/hooks/useRouter'
import type { BrowserLocation } from 'd2/types'

// TODO: move to d2/src/hooks
export function useScrollToTopOnChangePath () {
  const location: BrowserLocation = useLocation()
  useEffect(() => () => {
    defer(() => {
      scrollToTop()
    })
  }, [location.pathname])
}

const ScrollToTopOnChangePath = memo<EO>(() => {
  useScrollToTopOnChangePath()

  return null
})

ScrollToTopOnChangePath.displayName = 'ScrollToTopOnChangePath'

export default ScrollToTopOnChangePath
