import { Children, memo } from 'react'
import { Col, Grid } from 'd2/components/Grid'
import { compact } from 'lodash-es'
import type { Props } from './types'

const CardStack = memo<Props>(({
  children,
  sidebar, // Sometimes we are getting boolean value for sidebar prop... Is that right?
  sidebarPosition = 'left',
  sidebarSize = 4,
}) => (
  <Grid layout>
    { sidebar && sidebarPosition === 'left' && (
      <Col md={sidebarSize}>
        { sidebar === true ? '' : sidebar }
      </Col>
    ) }
    { /* @ts-expect-error (auto-migrated from flow FixMe)[incompatible-type] */ }
    <Col md={12 - (sidebar ? sidebarSize : 0)}>
      <Grid layout>
        { compact(Children.toArray(children)).map((child, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={index}>
            { child }
          </Col>
        )) }
      </Grid>
    </Col>
    { sidebar && sidebarPosition === 'right' && (
      <Col md={sidebarSize}>
        { sidebar === true ? '' : sidebar }
      </Col>
    ) }
  </Grid>
))

CardStack.displayName = 'CardStack'

export default CardStack
