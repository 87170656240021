/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import './webpack/publicPath'
import './vendor/polyfills'
import { init } from './analytics/fullstory'
import './dashboard_2.less.js'
import './dashboard_2/index'
import './analytics/wootric.js'
import './analytics/rage_click'
import './shared/webconsole'
import Turbolinks from 'd2/vendor/turbolinks'
import ready from './utils/ready'

ready(() => {
  init()
  Turbolinks.controller.stop()
})
