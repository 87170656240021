import { gql } from '@apollo/client'
import { mustSignUclaFragment } from 'd2/queries/shared'
import { useQuerySwitch } from 'd2/hooks/useQuerySwitch'
import type { HasSignedAgreements } from 'd2/queries'
import type { ReducerArg } from 'd2/hooks/useQuerySwitch'

const reducer = ({
  data,
}: ReducerArg<HasSignedAgreements>) => data?.me

export const useQuery = () => useQuerySwitch<HasSignedAgreements, EO, typeof reducer>(gql`
  query HasSignedAgreements {
    me {
      id
      ...UserMustSignUcla
      must_acknowledge_address_change
      show_optional_ucla
    }
  }
  ${mustSignUclaFragment}
`, {
  fetchPolicy: 'cache-and-network',

  reducer,
})
