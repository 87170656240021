import { MAIN_NAV_BAR_HEIGHT } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'

const styles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: px(MAIN_NAV_BAR_HEIGHT),
    minHeight: '100vh',
  },
  content: {
    flex: 1,
  },
} as const)

export default makeStyles({
  name: 'Dashboard',
})(styles)
