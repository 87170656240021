import { ElementType } from 'react'
import { Root, createRoot } from 'react-dom/client'
import invariant from 'invariant'

let root: Root | undefined

const mount = (rootWithProviders: React$Element<ElementType>) => {
  const rootEl = document.querySelector('#react-root')
  invariant(rootEl, 'Could not find #react-root %s', rootEl)

  root?.unmount()

  root = createRoot(rootEl)
  root.render(rootWithProviders)
}

export default mount
