/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import {
  clone,
  includes,
  isNil,
  some,
  startsWith,
} from 'lodash-es'
import { push } from 'redux-first-history'
import Turbolinks, { turbolinksEnabled } from 'd2/vendor/turbolinks'
import invariant from 'invariant'
import isTestEnv from './isTestEnv'
import raygun from 'd2/utils/raygun'
import type { Action } from 'redux'
import type { BrowserLocation } from 'd2/types'

type CallbackFn = () => void

let callbacks: CallbackFn[] = []
let currentLocation: string | null | undefined

function getLocation (): string {
  if (typeof window === 'undefined') return ''
  return window.location.pathname + window.location.search
}

function clearCallbacks (): void {
  callbacks = []
}

function triggerCallbacks (): void {
  for (const callback of clone(callbacks)) {
    callback()
  }
}

function trackVisitInRaygun (path: string): void {
  raygun('trackEvent', {
    path,
    type: 'pageView',
  })
}

function handleTurbolinksVisit (event: any): void {
  triggerCallbacks()

  if (!isTestEnv) {
    trackVisitInRaygun(event.data.url)
  }
}

function handlePopstate (): void {
  const newLocation: string = getLocation()

  if (newLocation !== currentLocation) {
    if (!isTestEnv) {
      triggerCallbacks()
    }
    currentLocation = newLocation
  }
}

function initialize (): void {
  clearCallbacks()
  if (typeof window !== 'undefined') {
    window.addEventListener('turbolinks:before-visit', handleTurbolinksVisit)
    window.addEventListener('popstate', handlePopstate)
  }
  currentLocation = getLocation()
}

export function d2VisitD1 (uri: string): void {
  if (typeof window === 'undefined') return
  window.location.href = uri
}

// DON'T USE THIS WHEN GOING TO D2 PAGES. Use withRouter and navigate
export function visit (uri: string): void {
  if (turbolinksEnabled) {
    Turbolinks.visit(uri)
  } else {
    d2VisitD1(uri)
  }
}

export function doesPathStartWith (loc: BrowserLocation | string, ...paths: string[]): boolean {
  const fullLocString: string = isNil(loc) ? '' : typeof loc === 'string' ? loc : loc.pathname + loc.search
  return some(paths, (path) => includes(fullLocString, path))
}

export function isD2Path (loc: BrowserLocation | string): boolean {
  return doesPathStartWith(loc, '/d2')
}

export function toOrHrefProps (current: BrowserLocation, destination: string): {
  href?: string,
  to?: string
} {
  return isD2Path(current) && isD2Path(destination) ? { to: destination } : { href: destination }
}

// DEPRECATED! This `navigate` function does not seem to work with TDD's assertPath.
//
// Instead use browser navigate, like:
//
// import { useNavigate } from 'react-router-dom'
// const navigate = useNavigate()
// navigate(d2Path)
export function navigate (
  {
    dispatch,
    location,
    to,
  }: {
    dispatch: ((a: any) => Action) | null | undefined,
    location: BrowserLocation,
    to: string
  },
): void {
  if (isD2Path(location)) {
    if (startsWith(to, '/d2')) {
      dispatch?.(push(to))
    } else {
      d2VisitD1(to)
    }
  } else {
    visit(to)
  }
}

initialize()

// ts-prune-ignore-next - Used in D1 JS
export default {

  offPageNavigation (callbackFunction: CallbackFn): void {
    const index = callbacks.indexOf(callbackFunction)
    invariant(index >= 0, `callbackFunction is not present in callbacks. ${callbackFunction.toString()}`)
    callbacks.splice(index, 1)
  },

  onPageNavigation (callbackFunction: CallbackFn): void {
    if (!includes(callbacks, callbackFunction)) callbacks.push(callbackFunction)
  },

}
