import $ from 'jquery'
import ready from '../utils/ready'

ready(() => {
  // Number of rage clicks
  const noOfClicks = 5
  // Time interval - 3 for 3 secs, 4 for secs and likewise
  const time = 3
  const clickEvents = []
  // internal vars
  const possibleClick = 5
  const radius = 50
  function detectXClicks (count, interval) {
    const last = clickEvents.length - 1
    const timeDiff = (clickEvents[last].time.getTime() - clickEvents[last - count + 1].time.getTime()) / 1000
    // returns false if it event period is longer than 5 sec
    if (timeDiff > interval) return null
    // check click distance
    let maxDistance = 0
    for (let i = last - count + 1; i < last; i++) {
      for (let j = i + 1; j <= last; j++) {
        const distance = Math.round(Math.sqrt(Math.pow(clickEvents[i].event.clientX - clickEvents[j].event.clientX, 2) + Math.pow(clickEvents[i].event.clientY - clickEvents[j].event.clientY, 2)))
        if (distance > maxDistance) maxDistance = distance
        if (distance > radius) return null
      }
    }
    return {
      count,
      maxDistance,
      timeDiff,
    }
  }
  function removeUsedClickPoints (count) {
    clickEvents.splice(clickEvents.length - count, count)
  }
  $('body').click((event) => {
    clickEvents.push({
      event,
      time: new Date(),
    })
    // remain only required number of click events and remove left of them.
    if (clickEvents.length > possibleClick) {
      clickEvents.splice(0, clickEvents.length - possibleClick)
    }
    // detect 5 click in 3 sec
    if (clickEvents.length >= 5) {
      const result = detectXClicks(noOfClicks, time)
      if (result != null) {
        const selected = $(event.target)
        const classes = selected.attr('class')
        const text = selected.text()
        const testID = selected.closest('[data-test-id]')?.attr('data-test-id')
        const parentClass = selected.parent()?.attr('class')
        const replacedTestID = testID?.replace(/-(\d+)/, '-:id')
        const tags = ['D2', 'rage']
        window.rg4js?.('send', {
          customData: {
            error: replacedTestID ? `rage click on ${replacedTestID}` : 'rage click',
            elementClass: classes,
            parentClass,
            maxDistance: result.maxDistance,
            testID,
            text,
            numberOfClicks: result.count,
            time: result.timeDiff,
          },
          error: replacedTestID ? `Rage click has occurred on ${replacedTestID}` : 'Rage click has occurred',
          tags: replacedTestID ? tags.concat(replacedTestID) : tags,
        })
        removeUsedClickPoints(noOfClicks)
      }
    }
  })
})
