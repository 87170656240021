import { FormValues, Props } from './types'
import { OtpMethodType } from 'd2/queries'
import { includes } from 'lodash-es'
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { scrollToTop } from 'd2/utils/ScrollTo'
import { useSecuritySettingsQuery } from './queries'
import { useToggleOtpMfaMutation } from 'd2/queries/shared'
import Body from 'd2/components/Typography/Body'
import CheckboxField from 'd2/components/Form/CheckboxField'
import Divider from 'd2/components/Divider'
import Flexbox from 'd2/components/Layout/Flexbox'
import Form from 'd2/components/Form/Form'
import Heading3 from 'd2/components/Typography/Heading3'
import Providers from 'd2/providers/standalone'
import SubmitButton from 'd2/components/ButtonV2/SubmitButton'
import useOtpModal from 'd2/hooks/useOtpModal'
import useSnackbar from 'd2/hooks/useSnackbar'
import useStyles from './styles'
import useTranslations from './translations'

const SecuritySettings = memo<Props>(({
  d1Component = false,
  onError,
}) => {
  const { classes } = useStyles()
  const [data, querySwitch] = useSecuritySettingsQuery()
  const { showSnackbar } = useSnackbar()
  const initialFormValues: FormValues = useMemo(() => ({
    otpMfa: data?.otpMfa,
  }), [data])
  const [formValues, setFormValues] = useState<FormValues>(initialFormValues)
  const [toggleOtpMfa, { formErrors, loading }] = useToggleOtpMfaMutation()
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (!data || initialDataLoaded) return
    setInitialDataLoaded(true)
    setFormValues(initialFormValues)
  }, [
    initialFormValues,
    data,
    setInitialDataLoaded,
    initialDataLoaded,
  ])

  const t = useTranslations()

  const handleErrors = useCallback((messages: string[] | undefined) => {
    if (includes(messages, 'cannot_be_disabled')) {
      showSnackbar({
        message: t.cannotBeDisabled,
        type: 'error',
      })
    }
    if (includes(messages, 'must_be_valid_sms')) {
      showSnackbar({
        message: t.invalidPhone,
        type: 'error',
      })
      scrollToTop()
    }
  }, [t, showSnackbar])

  const handleOnClose = useCallback(() => {
    setFormValues(initialFormValues)
  }, [initialFormValues])

  const handleOnSuccess = useCallback(async (hideModal: () => void) => {
    const result = await toggleOtpMfa({
      otp_mfa: formValues.otpMfa,
      skip_save: false,
      verify_method: OtpMethodType.sms,
    })
    if (result.errors.length) {
      handleErrors(result.errors[0]?.messages)
    }
    if (!result.errors.length) {
      showSnackbar({
        message: t.success(formValues.otpMfa),
        type: 'success',
      })
      hideModal()
    }
  }, [
    showSnackbar,
    t,
    handleErrors,
    toggleOtpMfa,
    formValues,
  ])

  const {
    hideModal,
    otpModal,
    showModal,
  } = useOtpModal({
    modalProps: {
      authMethod: OtpMethodType.sms,
      forceSendInitialOtp: true,
      modalIndex: 'security',
      onSuccess: () => handleOnSuccess(hideModal),
      onToggleClose: handleOnClose,
      otpOnly: true,
      redirect: false,
    },
  })

  const handleOnSubmit = useCallback(
    async (otpMfa: boolean | null | undefined) => {
      const result = await toggleOtpMfa({
        otp_mfa: otpMfa,
        skip_save: true,
        verify_method: OtpMethodType.sms,
      })
      if (result.errors.length) {
        handleErrors(result.errors[0]?.messages)
        setFormValues({
          otpMfa: data?.otpMfa ?? false,
        })
        onError?.(result.errors)
      } else {
        showModal()
      }
    },
    [
      toggleOtpMfa,
      showModal,
      data,
      handleErrors,
      onError,
    ],
  )

  const onFormChange = useCallback((values: {
    otpMfa: boolean
  }) => {
    setFormValues({ ...values })
    if (!d1Component && data && values.otpMfa !== data.otpMfa) {
      handleOnSubmit(values.otpMfa)
    }
  }, [d1Component, data, handleOnSubmit])

  const handleButtonSubmit = useCallback(() => {
    handleOnSubmit(formValues.otpMfa)
  }, [formValues.otpMfa, handleOnSubmit])

  if (!data) return querySwitch

  return (<>
    <Form
      disableHtml5Required
      errors={d1Component ? formErrors : null}
      onChange={onFormChange}
      testID='SecuritySettingsForm'
      value={formValues}
    >
      { d1Component
        ? <Heading3>
          { t.heading }
        </Heading3>
        : undefined }
      <Body>
        { t.info }
      </Body>
      <Divider />
      <Flexbox align="spaceBetween">
        <Body variant='bold'>
          { t.otpLabel }
        </Body>
        <CheckboxField
          className={classes.checkbox}
          disabled={loading}
          path='otpMfa'
          variant='switch'
        />
      </Flexbox>
      <Divider />
      { d1Component
        ? <Flexbox align='end'>
          <SubmitButton
            disabled={formValues.otpMfa === data.otpMfa}
            mutationLoading={loading}
            onClick={handleButtonSubmit}
            size='large'
            testID='SecuritySettingsFormSubmitButton'
          >
            { t.submit }
          </SubmitButton>
        </Flexbox>
        : undefined }
    </Form>
    { otpModal }
  </>)
})

SecuritySettings.displayName = 'SecuritySettings'

export const ComponentWithProviders = memo<Props>((props) => (<Providers>
  <SecuritySettings {...props} />
</Providers>))

ComponentWithProviders.displayName = 'SecuritySettings'

export default SecuritySettings
