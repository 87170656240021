import { memo } from 'react'
import { useIsMobile } from 'd2/components/Responsive'
import Body from 'd2/components/Typography/Body'
import HyperLink from 'd2/components/Typography/HyperLink'
import useStyles from './styles'
import useTranslations from './translations'
import type { Props } from './types'

const ObserverModeBanner = memo<Props>(({
  observeeName,
  observerName,
}) => {
  const isMobile = useIsMobile()
  const { classes } = useStyles({ isMobile })
  const t = useTranslations()

  return (
    <div
      className={classes.root}
      data-test-id='ObserverModeBanner'
    >
      <Body
        className={classes.bannerText}
        variant='semibold'
      >
        { t.renderMessage({
          clickable: (
            <HyperLink
              className={classes.signInBackLink}
              href='/stop_observing'
            >
              { isMobile ? t.returnTo(observerName) : t.clickHere }
            </HyperLink>
          ),
          prepend: isMobile ? t.prependStringMobile(observeeName) : t.prependStringDefault(observeeName, observerName),
        }) }
      </Body>
    </div>
  )
})

ObserverModeBanner.displayName = 'ObserverModeBanner'

export default ObserverModeBanner
