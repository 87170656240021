import { lazy, memo } from 'react'

export type Props = {
  standalone?: boolean
}

const Default = lazy<React$ComponentType>(
  () => import('d2/screens/StorageConsumption'),
)

const Standalone = lazy<React$ComponentType>(
  () => import('d2/screens/StorageConsumption/standalone'),
)

const StorageConsumptionScreen = memo<Props>(({ standalone }) => (
  standalone ? <Standalone /> : <Default />
))

export default StorageConsumptionScreen
