import { memo } from 'react'
import Providers from 'd2/providers/standalone'
import StorageTierModalWrapper from './template'

export const ComponentWithProviders = memo<EO>(() => (<Providers>
  <StorageTierModalWrapper />
</Providers>))

ComponentWithProviders.displayName = 'StorageTierModalWrapperWithProviders'

export { default } from './template'
