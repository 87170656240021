import {
  Children,
  cloneElement,
  isValidElement,
  memo,
} from 'react'
import { forwardProps } from 'd2/utils/props'
import useStyles from './styles'
import type { Props, StretchChild } from './types'

/*
Indicates if this child is the one to stretch.
*/
function shouldStretch (index: number, count: number, stretch: StretchChild): boolean {
  if (stretch === index) return true
  if (stretch === 'first' && index === 0) return true
  if (stretch === 'last' && index === count - 1) return true
  return !!(stretch === 'middle' && count % 2 === 1 && index === (count - 1) / 2)
}

/*
Renders child components horizontally, sizing each one to its minimum width (auto) except for
one that fills all the remaining space.
*/
const StretchOne = memo<Props>(({
  children,
  className,
  stretch,
  vertical,
  ...props
}) => {
  const { classes, cx } = useStyles()
  const childrenCount = Children.count(children)

  return (
    <div
      className={cx({
        [classes.containerHorizontal]: !vertical,
        [classes.containerVertical]: vertical,
      }, className)}
      {...forwardProps(props)}
    >
      {
        Children.map(children, (child, i: number) => {
          if (shouldStretch(i, childrenCount, stretch)) {
            return isValidElement(child)
              ? cloneElement(child, {
                // @ts-expect-error TS version 4.8 started complaining about cloneElement's props.
                className: cx(child.props.className, classes.stretchChild),
              })
              : child
          }
          return child
        })
      }
    </div>
  )
})

StretchOne.displayName = 'StretchOne'

export default StretchOne
