import { Props } from './types'
import { memo } from 'react'
import MuiPhoneNumberInput from 'material-ui-phone-number'
import Providers from 'd2/providers/standalone'
import useTranslations from './translations'

const PhoneNumberInput = memo<Props>(({
  disabled,
  disabledText,
  enableHelperText = true,
  ...props
}) => {
  const t = useTranslations()
  return (
    <MuiPhoneNumberInput
      defaultCountry='us'
      disabled={disabled}
      enableLongNumbers
      {...props}
      helperText={enableHelperText && (disabled || disabledText ? t.disableOtp : t.helperText())}
      // Temporary fix for safari and firefox found here: https://github.com/alexplumb/material-ui-phone-number/issues/124#issuecomment-1017297624
      sx={{
        svg: {
          height: '20px',
        },
      }}
    />
  )
})

export const ComponentWithProviders = memo<Props>((props) => (
  <Providers>
    <PhoneNumberInput {...props} />
  </Providers>
))

PhoneNumberInput.displayName = 'PhoneNumberInput'
ComponentWithProviders.displayName = 'PhoneNumberInputD1'

export default PhoneNumberInput
