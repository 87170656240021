import '../vendor/fullstory'
import ready from '../utils/ready'

export function init () {
  const user = window.current_user
  if (window.FS && user && user.id) {
    const properties = {
      createdAt_str: user.created_at,
      email_str: user.email,
      estimatedRevenue_real: user.estimated_revenue.to_f,
      fullName_str: user.full_name,
      currentTier_int: (user.use_override_tier ? user.override_user_tier : user.current_tier) || 1000,
      lifetimeTier_int: (user.use_override_tier ? user.override_user_tier : user.lifetime_tier) || 1000,
      tierIsEstimate_bool: user.use_override_tier,
      isAdmin_bool: user.is_admin || window.adminLayout,
      organizationRole_str: user.role,
      phone_str: user.phone,
      userId_int: user.id,
    }
    properties.displayName = user.name || user.email
    const observer = window.current_observer
    if (window.FS && observer && observer.id) {
      properties.observerId_int = observer.id
    }
    const features = window.enabledFeatures
    features.forEach((feature) => {
      feature = feature.replace(/(_\w)/g, (m) => m[1].toUpperCase())
      properties[`hasFeature${feature}_bool`] = true
    })
    window.FS.identify(user.id, properties)
  }
}

ready(init)
