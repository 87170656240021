type ReadyListItem = {
  ctx: any,
  fn: AnyFunction
}

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
typeof window !== 'undefined' && (function (funcName, baseObj: Window & typeof globalThis | undefined): void {
  // The public function name defaults to window.documentReady
  // but you can pass in your own object and own function name and those will be used
  // if you want to put them in a different namespace
  funcName = funcName || 'documentReady'
  baseObj = baseObj ?? window
  let readyList: ReadyListItem[] = []
  let readyFired = false
  let readyEventHandlersInstalled = false

  // call this when the document is ready
  // this function protects itself against being called more than once
  function ready () {
    if (!readyFired) {
      // this must be set to true before we start calling callbacks
      readyFired = true
      for (const callback of readyList) {
        // if a callback here happens to add new ready handlers,
        // the documentReady() function will see that it already fired
        // and will schedule the callback to run right after
        // this event loop finishes so all handlers will still execute
        // in order and no new ones will be added to the readyList
        // while we are processing the list
        callback.fn.call(window, callback.ctx)
      }
      // allow any closures held by these functions to free
      readyList = []
    }
  }

  function readyStateChange () {
    if (document.readyState === 'complete') {
      ready()
    }
  }

  // This is the one public interface
  // documentReady(fn, context);
  // the context argument is optional - if present, it will be passed
  // as an argument to the callback
  // @ts-expect-error window doesn't have the func names
  baseObj[funcName]
    = function (callback: AnyFunction, context: any) {
      if (typeof callback !== 'function') {
        throw new TypeError('callback for documentReady(fn) must be a function')
      }
      // if ready has already fired, then just schedule the callback
      // to fire asynchronously, but right away
      if (readyFired) {
        setTimeout(() => {
          callback(context)
        }, 1)
        return
      }
      // add the function and context to the list
      readyList.push({
        ctx: context,
        fn: callback,
      })

      // if document already ready to go, schedule the ready function to run
      if (document.readyState === 'complete') {
        setTimeout(ready, 1)
      } else if (!readyEventHandlersInstalled) {
      // otherwise if we don't have event handlers installed, install them
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (document.addEventListener) {
        // first choice is DOMContentLoaded event
          document.addEventListener('DOMContentLoaded', ready, false)
          // backup is window load event
          window.addEventListener('load', ready, false)
          // @ts-expect-error for old ie versions
        } else if (document.attachEvent) {
          // must be IE
          // @ts-expect-error for old ie versions
          document.attachEvent('onreadystatechange', readyStateChange)
          // @ts-expect-error for old ie versions
          window.attachEvent('onload', ready)
        }
        readyEventHandlersInstalled = true
      }
    }
})('documentReady', window)

export default {}
