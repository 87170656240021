import { SPACING } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

const styles = (
  {
    ...theme
  }: MergedThemes,
) => ({
  bannerText: {
    color: theme.branding?.primaryContrastTextColor,
  },
  root: {
    backgroundColor: theme.branding?.info,
    padding: px(SPACING),
    textAlign: 'center',
  },
} as const)
export default makeStyles({
  name: 'AppAnnouncementsBanner',
})(styles)
