import { SPACING, SPACING_HALF } from 'd2/constants'
import { px } from 'd2/utils/style'
import makeStyles from 'd2/hooks/tss/makeStyles'
import type { MergedThemes } from 'd2/types'

type StylesProps = {
  isMobile: boolean,
}

const styles = (
  {
    fontBoldStyles,
    ...theme
  }: MergedThemes,
  { isMobile }: StylesProps,
) => ({
  bannerText: {
    color: theme.branding?.primaryContrastTextColor,
  },
  root: {
    backgroundColor: theme.branding?.primaryColor,
    padding: isMobile ? px(SPACING_HALF) : px(SPACING),
    textAlign: 'center',
  },
  signInBackLink: {
    ...fontBoldStyles,
    color: `${theme.branding?.primaryContrastTextColor ?? 'transparent'} !important`,
    textDecoration: 'underline',
  },
} as const)

export default makeStyles<StylesProps>({
  name: 'ObserverModeBanner',
})(styles)
