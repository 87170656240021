/* eslint-disable babel/camelcase */
import {
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { some } from 'lodash-es'
import { useConfirmPassword } from './queries'
import { useResetPassword, useUserAccountDetailsQuery } from 'd2/queries/shared'
import Body from 'd2/components/Typography/Body'
import BodyExtraSmall from 'd2/components/Typography/BodyExtraSmall'
import Card from 'd2/components/Card'
import Form from 'd2/components/Form/Form'
import Heading3 from 'd2/components/Typography/Heading3'
import HyperLink from 'd2/components/Typography/HyperLink'
import Providers from 'd2/providers/standalone'
import SnackbarNotification from 'd2/components/SnackbarNotification'
import SubmitButton from 'd2/components/ButtonV2/SubmitButton'
import TextField from 'd2/components/Form/TextField'
import useSnackbar from 'd2/hooks/useSnackbar'
import useTranslations from './translations'
import type { Props } from './types'

const ConfirmPasswordForm = memo<Props>(({
  onSuccess,
}) => {
  const t = useTranslations()
  const [data] = useUserAccountDetailsQuery()
  const [resetPassword, { loading: resetPasswordMutationLoading }] = useResetPassword()
  const [confirmPassword, { formErrors }] = useConfirmPassword()
  const { showGenericErrorSnackbar, showSnackbar } = useSnackbar()
  const [formValues, setFormValues] = useState<{
    confirm_password: string
  }>({ confirm_password: '' })

  const handleResetPasswordClick = useCallback(async () => {
    const { errors, success } = await resetPassword({ email: data?.me.email })
    if (some(errors) && !success) {
      showGenericErrorSnackbar()
    } else {
      showSnackbar({
        message: t.checkEmail,
        type: 'success',
      })
    }
  }, [
    resetPassword,
    data,
    showGenericErrorSnackbar,
    showSnackbar,
    t,
  ])

  const handleSubmit = useCallback(async () => {
    const { success } = await confirmPassword({
      confirm_password: formValues.confirm_password,
    })
    if (success) {
      onSuccess()
    }
  }, [confirmPassword, formValues, onSuccess])

  const onChange = useCallback((values: React.SetStateAction<{
    confirm_password: string;
  }>) => {
    setFormValues(values)
  }, [])

  const bottomButtons = useMemo(() => (<SubmitButton
    size='large'
    testID='VerifyPassword-button'
    type='submit'
  >
    { t.verify }
  </SubmitButton>), [t.verify])

  const verifyPasswordTitle = useMemo(() => (<Heading3>
    { t.verifyIdentity }
  </Heading3>), [t.verifyIdentity])

  const here = useMemo(() => (<HyperLink
    disabled={resetPasswordMutationLoading}
    onClick={handleResetPasswordClick}
  >
    <BodyExtraSmall>
      { t.here }
    </BodyExtraSmall>
  </HyperLink>), [handleResetPasswordClick, t, resetPasswordMutationLoading])

  return (

    <Form
      disableHtml5Required
      errors={formErrors}
      onChange={onChange}
      onSubmit={handleSubmit}
      testID='ConfirmPasswordForm'
      value={formValues}
    >
      <Card
        bottomButtons={bottomButtons}
        noBorder
        paddingSize='none'
        title={verifyPasswordTitle}
        titleAlign='center'
        usePaper={false}
      >
        <Body>
          { t.confirmationText }
        </Body>
        <TextField
          fullWidth
          helperText={t.helperText(here)}
          label={t.currentPassword}
          path='confirm_password'
          required
          type='password'
        />
      </Card>
    </Form>

  )
})

ConfirmPasswordForm.displayName = 'ConfirmPasswordForm'

export const ComponentWithProviders = memo<Props>((props) => (<Providers>
  <Card>
    <ConfirmPasswordForm {...props} />
  </Card>
  <SnackbarNotification />
</Providers>))

ComponentWithProviders.displayName = 'ConfirmPasswordForm'

export default ConfirmPasswordForm
