import { forwardProps } from 'd2/utils/props'
import { memo } from 'react'
import useStyles from './styles'
import type { Props } from './types'

/*
Renders two components horizontally, one on the left and one on the right of edges. Anything else is unsupported.
*/
const SpaceBetween = memo<Props>(({
  children,
  className,
  ...props
}) => {
  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.container, className)}
      {...forwardProps(props)}
    >
      { children }
    </div>
  )
})

SpaceBetween.displayName = 'SpaceBetween'

export default SpaceBetween
