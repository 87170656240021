// TODO: d2ify

import { isTDDBrowser } from 'd2/utils/tdd'
import TestUtils from 'react-dom/test-utils'

const TestHelpers = {
  isTestEnv (): boolean {
    // @ts-expect-error adding window.settingsEnv
    return (typeof window !== 'undefined' && window.settingsEnv === 'test') || isTDDBrowser()
  },
  patchTestEnv () {
    if (typeof window === 'undefined') return

    // @ts-expect-error adding window.testEnvHasBeenPatched
    if (window.testEnvHasBeenPatched || !this.isTestEnv()) {
      return
    }
    // @ts-expect-error adding window.testEnvHasBeenPatched
    window.testEnvHasBeenPatched = true

    const Simulate = TestUtils.Simulate

    // when you select an option with phantomjs
    // react onchange listeners are not automatically triggered
    // this is a hack for now to get around that
    // currently in use when selecting options from tests
    // see spec_extensions/request_modules.rb:select_js
    // @ts-expect-error adding window.triggerChangeById
    window.triggerChangeById = function (elementId: string) {
      // @ts-expect-error (auto-migrated from flow FixMe)[incompatible-call]
      Simulate.change(document.getElementById(elementId)) // eslint-disable-line unicorn/prefer-query-selector
    }

    // @ts-expect-error adding window.getElementByXpath
    window.getElementByXpath = function (xpath: string) {
      return document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue
    }
  },
}
TestHelpers.patchTestEnv()

export default TestHelpers.isTestEnv()
