/* eslint-disable no-console */
/* eslint-disable unicorn/prefer-module */

import './vendor/document-ready' // eslint-disable-line import/no-internal-modules
import Providers from './providers'
import mount from './mount'

const loadTDDMounter: () => void = () => {
  console.log('JavaScripts running in TDD mode. Loading TDD bundle...')

  import(/* webpackChunkName: "tdd" */ './tdd')
    .then((loaded) => {
      console.log('TDD bundle loaded!')
      return loaded
    })
    .catch((error) => {
      console.error('TDD bundle failed to load!')
      throw error
    })
}

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires
  const { default: Root } = require('d2/root')

  // TODO: Try adding StrictMode back in. Removed when switching to react 18 concurrent rendering.
  // import { StrictMode } from 'react'
  mount(
    <Providers>
      <Root />
    </Providers>,
  )
}

if (typeof window !== 'undefined') {
  // @ts-expect-error custom window things
  if (window.VYDIA_SETTINGS?.TDD || window.sessionStorage.getItem('TDD')) {
    loadTDDMounter()
  } else {
    // @ts-expect-error custom window things
    window.documentReady(render)
  }
}
