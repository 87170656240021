import { Outlet } from 'react-router-dom'
import { memo } from 'react'
import SharedLayout from './SharedLayout'

const PartialLayout = memo<EO>(() => (
  <SharedLayout>
    <Outlet />
  </SharedLayout>
))

PartialLayout.displayName = 'PartialLayout'

export default PartialLayout
