/*
Use this when you need to track the route change
*/
import { trackRouteChanged } from 'd2/analytics/GoogleTracker'
import { urlQueryParamIdFormat } from 'd2/utils/Routes'
import { useEffect } from 'react'
import { useLocation } from 'd2/hooks/useRouter'

export default function useTracking (): void {
  const { pathname } = useLocation()
  useEffect(() => {
    trackRouteChanged(urlQueryParamIdFormat(pathname || ''))
  }, [pathname])
}
