// NavigateDynamic is used to navigate to a dynamic route where the param is interpolated into the `to` path
//
// @example - Navigate to "/d2/deals/123/edit" when "/d2/deals/123" is visited.
//   You don't have to build a `view` screen if the `edit` screen is like a google doc,
//   where you click into the document and you can view and edit it as one experience.
//
//   <Route
//     element={<NavigateDynamic to="/d2/deals/:dealId/edit" />}
//     path=':dealId'
//   />

import {
  Navigate,
  NavigateProps,
  useParams,
} from 'react-router'
import { memo, useMemo } from 'react'

// Wrap Navigate component but the `to` prop gets modified.
// Replace :param with the param from react-router.
const NavigateDynamic = memo(({ to, ...props }: NavigateProps & { to: string }) => {
  // in `to` prop, map each :param into an array
  // for each, get from params

  const params = useParams()

  const newTo = useMemo(
    () => to.split('/').map((part) => {
      if (part.startsWith(':')) {
        const param = part.slice(1)
        return params[param]
      }
      return part
    })
      .join('/'),
    [to, params],
  )

  return (<Navigate
    to={newTo}
    {...props}
  />)
})

NavigateDynamic.displayName = 'NavigateDynamic'

export default NavigateDynamic
