import { Navigate, useSearchParams } from 'react-router-dom'
import { coerceBoolean, useUrlQueryParam } from 'd2/hooks/useUrlQueryParam'
import { memo } from 'react'
import { useLocation } from 'd2/hooks/useRouter'
import { useQuery } from './queries'
import type { Props } from './types'

/*
A simple way to ensure a component only shows if the user has signed required agreements. If the user
has not signed a required agreement, it will present them with that agreement to sign.
*/

const NeedsAgreement = memo<Props>((
  {
    children,
    forAddressChange,
    forEcla,
    forOwnerEcla,
    skippable,
  },
) => {
  const [me, querySwitch] = useQuery()
  const { pathname, search } = useLocation()
  const [searchParams] = useSearchParams()
  const [skipped] = useUrlQueryParam('skipped', coerceBoolean)

  if (!me) {
    return querySwitch
  }

  if (
    forAddressChange
    && me.must_acknowledge_address_change
    && (
      pathname !== '/d2/dashboard'
      || searchParams.get('modal') !== 'AddressChangeModal'
    )
  ) {
    return <Navigate to="/d2/dashboard?modal=AddressChangeModal" />
  }

  const signedAgreementRequired = (forEcla && me.must_sign_ucla) || (forOwnerEcla && me.requires_label_owner_agreement)
  const cannotBeSkipped = !skippable || (me.show_optional_ucla && !skipped)

  if (signedAgreementRequired && cannotBeSkipped) {
    return <Navigate to={`/d2/ecla?${skippable ? 'skippable=true&' : ''}return_url=${encodeURIComponent(pathname + search)}`} />
  }

  return (<>
    { children }
  </>)
})

NeedsAgreement.displayName = 'ConnectedComponent'

export default NeedsAgreement
