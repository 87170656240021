import googleAnalytics from 'react-ga'

const enabled: boolean = typeof window !== 'undefined'
  // @ts-expect-error window.google_analytics_account not exist?
  && window.google_analytics_account?.length > 0

export const trackRouteChanged: (path: string) => void = (path) => {
  if (!enabled) return

  let displayMode = 'browser'
  const mqStandAlone = '(display-mode: standalone)'
  if (
    // @ts-expect-error window.navigator.standalone not exist?
    window.navigator.standalone
    || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone'
  }
  googleAnalytics.set({ dimension1: displayMode })
  googleAnalytics.pageview(path)
}

if (enabled) {
  googleAnalytics.initialize(
    // @ts-expect-error window.google_analytics_account not exist?
    window.google_analytics_account,
  )
}
