/*
Top level component that provides basic integrations for Redux, Apollo, Router, etc.

There are multiple providers. This one is the default, used during normal operation (like production.)
*/
import { Component } from 'react'
import BaseProvider from './BaseProvider'
import apollo from 'd2/utils/apollo'
import createStoreResources from 'd2/store/createStoreResources'
import type { History } from 'history'
import type { Store } from 'd2/types'
import type { StoreResources } from 'd2/store/createStoreResources'

type Props = {
  children?: React$Node
}

export default class extends Component<Props> {
  history: History
  store: Store

  constructor (props: Props) {
    super(props)

    const {
      history,
      store,
    }: StoreResources = createStoreResources()
    this.history = history
    this.store = store
  }

  render = () => {
    const { history, props: { children }, store } = this

    return (<BaseProvider
      apolloClient={apollo()}
      history={history}
      store={store}
    >
      { children }
    </BaseProvider>)
  }
}
