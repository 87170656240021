import { d } from 'd2/utils'
import { memo } from 'react'
import NotFoundScreen from 'd2/loadables/NotFoundScreen'
import useTheme from 'd2/hooks/useTheme'

const ForLabelHub = memo<{
  children: React$Node
}>(({ children }) => {
  const { branding } = useTheme()
  const { isWhitelabel } = d(branding)

  return isWhitelabel
    ? <>
      { children }
    </>
    : <NotFoundScreen />
})

ForLabelHub.displayName = 'ForLabelHub'

export default ForLabelHub
