import { isFunction } from 'lodash-es'
import { memo } from 'react'
import Divider from 'd2/components/Divider'
import Heading3 from 'd2/components/Typography/Heading3'
import StackedOnPhone from 'd2/components/StackedOnPhone'
import Truncate from 'd2/components/Typography/Truncate'
import useStyles from './styles'
import type { Props } from './types'

const CardSubSection = memo<Props>(({
  children,
  dividerProps,
  noContentMargin,
  noDivider,
  noPadding,
  noPaddingBottom,
  noPaddingLeft,
  rightSideContent,
  tinyButtons,
  title,
  topButtons,
}) => {
  const { classes, cx } = useStyles()

  return (<>
    <StackedOnPhone tinyButtons={tinyButtons}>
      { title && (
        <>
          <Truncate title={title}>
            {
              isFunction(title)
                ? <title />
                : <Heading3 variant='light'>
                  { title }
                </Heading3>
            }
          </Truncate>
          { rightSideContent }
        </>
      ) }
      { topButtons && (
        <div className={classes.buttonsWrapper}>
          { topButtons }
        </div>
      ) }
    </StackedOnPhone>
    { !noDivider && <Divider {...dividerProps ?? { halfMargin: true }} /> }
    <div
      className={cx({
        [classes.contentWithMargin]: noDivider && !noContentMargin,
        [classes.paddingBottom]: !noPadding && !noPaddingBottom,
        [classes.paddingLeft]: !noPadding && !noPaddingLeft,
      })}
    >
      { children }
    </div>
  </>
  )
})

CardSubSection.displayName = 'CardSubSection'

export default CardSubSection
