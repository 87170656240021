import {
  AVATAR_EXTRA_SMALL_SIZE,
  SPACING_HALF,
  SPACING_ZERO,
} from 'd2/constants'
import {
  AuthenticationPickerProps,
  FormValues,
  Props,
} from './types'
import {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Col, Grid } from 'd2/components/Grid'
import { OtpMethodType } from 'd2/queries'
import { SelectedValues } from 'd2/components/Picker/types'
import { d2VisitD1 } from 'd2/utils/Navigation'
import {
  filter,
  head,
  isNil,
  some,
} from 'lodash-es'
import { useFormState } from 'd2/components/Form/Form'
import { useIsMobile } from 'd2/components/Responsive'
import { useNavigate } from 'd2/hooks/useRouter'
import { useOneTimePasswordQuery, useSendOtpMutation } from './queries'
import { useToggleOtpMfaMutation, useVerifyOtpMutation } from 'd2/queries/shared'
import BlankButton from 'd2/components/ButtonV2/BlankButton'
import Body from 'd2/components/Typography/Body'
import Box from 'd2/components/Box'
import ButtonContainer from 'd2/components/ButtonContainer'
import Card from 'd2/components/Card'
import CheckboxList from 'd2/components/CheckboxList'
import Flexbox from 'd2/components/Layout/Flexbox'
import GradientIcon from 'd2/components/AvatarIcon/GradientIcon'
import Heading2 from 'd2/components/Typography/Heading2'
import Heading3 from 'd2/components/Typography/Heading3'
import Heading4 from 'd2/components/Typography/Heading4'
import Heading5 from 'd2/components/Typography/Heading5'
import HyperLink from 'd2/components/Typography/HyperLink'
import LinkButton from 'd2/components/ButtonV2/LinkButton'
import OTPInput from 'd2/components/OTPInput'
import OtpQrCode from 'd2/components/OtpQrCode'
import PhoneNumberInput from 'd2/components/PhoneNumberInput'
import Picker from 'd2/components/Picker'
import Providers from 'd2/providers/standalone'
import SideModal from 'd2/components/SideModal'
import SolidButton from 'd2/components/ButtonV2/SolidButton'
import Spacer from 'd2/components/Spacer'
import cx from 'classnames'
import invariant from 'invariant'
import useModal from 'd2/hooks/useModal'
import useReturnUrl from 'd2/hooks/useReturnUrl'
import useSnackbar from 'd2/hooks/useSnackbar'
import useStyles from './styles'
import useTranslations from './translations'
import type { VerificationMethod } from 'd2/components/OneTimePasswordModal/types'

const OTP_LENGTH = 6
const VERIFICATION_METHOD_STEP = 1
const ADD_PHONE_NUMBER_STEP = 2
const ENTER_CODE_STEP = 3
const VERIFICATION_METHOD_PHONE: VerificationMethod = 'sms'
const VERIFICATION_METHOD_AUTHENTICATOR: VerificationMethod = 'authenticator'

export const AuthenticationMethodPicker = memo<AuthenticationPickerProps>(({
  isAdmin,
  onChangeVerificationMethod,
  selectedVerificationMethod,
}) => {
  const t = useTranslations()
  const appOption = {
    icon: <GradientIcon
      fontSize='large'
      icon='mobile'
      iconWeight='light'
      size={AVATAR_EXTRA_SMALL_SIZE}
    />,
    name: <Heading5>
      { t.authAppName }
      <Heading5 variant="bold">
        { t.mostSecure }
      </Heading5>
    </Heading5>,
    subText: <Body>
      { t.authAppDescription }
    </Body>,
    testID: 'OneTimePasswordForm-Picker-authenticator',
    value: VERIFICATION_METHOD_AUTHENTICATOR,
  }
  const smsOption = {
    icon: <GradientIcon
      fontSize='large'
      icon='sms'
      iconWeight='light'
      size={AVATAR_EXTRA_SMALL_SIZE}
    />,
    name: <Heading5>
      { t.smsName }
    </Heading5>,
    subText: <Body>
      { t.smsDescription }
    </Body>,
    testID: 'OneTimePasswordForm-Picker-phone',
    value: VERIFICATION_METHOD_PHONE,
  }

  const items = isAdmin ? [appOption] : [appOption, smsOption]

  return (<>
    <Body>
      { t.verificationBody }
    </Body>
    <Spacer />
    <Picker
      colSizeSm={12}
      items={items}
      multiple={false}
      onChange={onChangeVerificationMethod}
      selectedValues={selectedVerificationMethod}
      testID='OneTimePasswordForm-Ftui-Picker-VerificationMethod'
      truncateSubtitle={false}
    />
  </>)
})

AuthenticationMethodPicker.displayName = 'AuthenticationMethodPicker'

const OneTimePasswordForm = memo<Props>(({
  authMethod,
  body,
  forceSendInitialOtp = false,
  onPhoneError,
  onSuccess,
  otpOnly,
  phoneNumber,
  redirect = true,
  rememberDevice = false,
  showLogoutButton = false,
  verifyOtp,
}) => {
  const returnUrl = useReturnUrl()
  const isMobile = useIsMobile()
  const t = useTranslations()
  const { classes } = useStyles()
  const [otp, setOtp] = useState('')
  const { hideModal, showModal } = useModal()
  const ref = useRef(null)
  const [verifyOtpMutation, { loading: verificationLoading }] = useVerifyOtpMutation()
  const [sendOtpMutation, { loading: sendOtpLoading }] = useSendOtpMutation()
  const [sentInitialOtp, setSentInitialOtp] = useState(false)
  const [rememberDeviceValue, setRememberDeviceValue] = useState<string[]>([])
  const [step, setStep] = useState<number>(VERIFICATION_METHOD_STEP)
  const [phoneError, setPhoneError] = useState<boolean>(false)
  const [data, querySwitch] = useOneTimePasswordQuery()
  const [toggleOtpMfa] = useToggleOtpMfaMutation()
  const [formValues, setFormValues] = useFormState<FormValues>(() => {
    if (!data) return { phone: '' }
    return {
      phone: data.phoneNumber,
    }
  })
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState<VerificationMethod>(authMethod ?? VERIFICATION_METHOD_AUTHENTICATOR)
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleSendOtpMutation = useCallback(
    async (fromFtui: boolean) => {
      if (data) {
        const input = fromFtui
          ? {
            phone: formValues.phone,
            save_phone: true,
          }
          : {
            phone: phoneNumber ?? data.phoneNumber,
          }
        const { errors } = await sendOtpMutation(input)

        if (errors.length) {
          showSnackbar({
            message: t.somethingWentWrong,
            type: 'error',
          })
          if (some(filter(errors, ({ key }) => key === 'phone'))) {
            setPhoneError(true)
            onPhoneError?.(errors)
          }
        } else if (fromFtui) {
          setStep(ENTER_CODE_STEP)
        } else {
          showSnackbar({
            message: t.newVerificationCode,
            type: 'info',
          })
        }
      }
    },
    [
      formValues.phone,
      onPhoneError,
      phoneNumber,
      sendOtpMutation,
      showSnackbar,
      t,
      data,
    ],
  )

  const checkBoxItems = useMemo(() => [
    {
      metadata: {
        title: <Body>
          { t.rememberDevice }
        </Body>,
      },
      value: 'remember_device',
    },
  ], [t.rememberDevice])

  const handleRedirect = useCallback(() => {
    if (!returnUrl) {
      navigate('/d2/')
    } else if (returnUrl.includes('d2')) {
      navigate(returnUrl)
    } else {
      d2VisitD1(returnUrl)
    }
  }, [returnUrl, navigate])

  const handleClickVerify = useCallback(async (callbackOtp: React.SyntheticEvent | string | undefined) => {
    if (!data) return

    const result = await verifyOtpMutation({
      code: typeof callbackOtp === 'string' && callbackOtp.length ? callbackOtp : otp,
      remember_device: rememberDeviceValue[0] === 'remember_device',
      verify_method: authMethod === VERIFICATION_METHOD_PHONE || (!verifyOtp && selectedVerificationMethod === VERIFICATION_METHOD_PHONE) || (verifyOtp && data.otpMfaMethod === OtpMethodType.sms) ? OtpMethodType.sms : OtpMethodType.authenticator,
    })
    if (result.errors.length) {
      showSnackbar({
        message: t.verificationFailed,
        type: 'error',
      })
    } else {
      showSnackbar({
        heading: t.success,
        message: verifyOtp ? t.otpVerified : selectedVerificationMethod === VERIFICATION_METHOD_PHONE ? t.verificationSuccess(formValues.phone?.slice(-4) ?? '') : t.authAppSuccess,
        type: 'success',
      })
      if (!isNil(onSuccess)) {
        onSuccess()
      }
      if (!data.otpMfa) {
        await toggleOtpMfa({
          otp_mfa: true,
          verify_method: authMethod === VERIFICATION_METHOD_PHONE || (!verifyOtp && selectedVerificationMethod === VERIFICATION_METHOD_PHONE) || (verifyOtp && data.otpMfaMethod === OtpMethodType.sms) ? OtpMethodType.sms : OtpMethodType.authenticator,
        })
      }
      if (redirect) {
        handleRedirect()
      }
    }
  }, [
    data,
    handleRedirect,
    verifyOtpMutation,
    otp,
    rememberDeviceValue,
    authMethod,
    selectedVerificationMethod,
    showSnackbar,
    t,
    verifyOtp,
    formValues.phone,
    onSuccess,
    redirect,
    toggleOtpMfa,
  ])

  const onChangeOTP = useCallback((value: React.SetStateAction<string>) => {
    setOtp(value)
  }, [])

  const handleClickResend = useCallback((fromFtui: boolean) => {
    handleSendOtpMutation(fromFtui)
  }, [handleSendOtpMutation])

  useEffect(() => {
    if (
      (!isNil(data) && data.otpMfa && !data.otpExpired)
      && redirect
    ) {
      handleRedirect()
    }
  }, [
    data,
    navigate,
    redirect,
    returnUrl,
    handleRedirect,
  ])

  useEffect(() => {
    if (!isNil(data) && (phoneNumber || data.phoneNumber) && !sentInitialOtp && authMethod === OtpMethodType.sms && ((data.otpMfa && data.otpExpired) || forceSendInitialOtp)) {
      handleSendOtpMutation(false)
      setSentInitialOtp(true)
    }
  }, [
    authMethod,
    data,
    phoneNumber,
    sentInitialOtp,
    sendOtpMutation,
    showSnackbar,
    handleSendOtpMutation,
    forceSendInitialOtp,
    t,
  ])

  const onChangeRememberDevice = useCallback((newCheckValues: string[]) => setRememberDeviceValue(newCheckValues), [])

  const onChangeVerificationMethod = useCallback((verificationMethods: SelectedValues) => {
    const verificationMethod = head(verificationMethods)
    invariant(verificationMethod === 'sms' || verificationMethod === 'authenticator', 'verification value must be either sms or authenticator. Got %s', verificationMethod)
    setSelectedVerificationMethod(verificationMethod)
  }, [])

  const onChangePhone = useCallback((newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    invariant(typeof newValue === 'string', 'newValue must be a string. Got %s', newValue)
    setFormValues({ phone: newValue })
  }, [setFormValues])

  const onClick = useCallback(() => setStep(ADD_PHONE_NUMBER_STEP), [])

  const bottomButtons = useMemo(() => (<SolidButton
    onClick={onClick}
    testID='OneTimePasswordForm-Ftui-VerificationMethod-continue'
  >
    { t.continue }
  </SolidButton>), [onClick, t])

  const title = useMemo(() => (<Heading2>
    { t.turnOn }
  </Heading2>), [t])

  const title2 = useMemo(() => (<Heading2>
    { t.verificationMethod }
  </Heading2>), [t])

  const title3 = useMemo(() => (<Heading2>
    { selectedVerificationMethod === VERIFICATION_METHOD_PHONE ? t.addPhoneNumber : t.scanQRCode }
  </Heading2>), [selectedVerificationMethod, t])

  const title4 = useMemo(() => (<Heading2>
    { t.enterCode }
  </Heading2>), [t])

  const onClick2 = useCallback(() => setStep(VERIFICATION_METHOD_STEP), [setStep])

  const onClick3 = useCallback(() => setStep(ADD_PHONE_NUMBER_STEP), [setStep])

  const onClick4 = useCallback(() => handleClickResend(false), [handleClickResend])

  const onClick5 = useCallback(() => handleSendOtpMutation(true), [handleSendOtpMutation])

  const verifyBottomButtons = useMemo(() => (<ButtonContainer
    align={isMobile ? 'center' : 'right'}
    maxWidth
  >
    <BlankButton
      className={classes.blankButton}
      color='danger'
      onClick={onClick2}
    >
      { t.goBack }
    </BlankButton>
    <SolidButton
      onClick={onClick5}
      testID='OneTimePasswordForm-Ftui-verify'
    >
      { t.verifyNumber }
    </SolidButton>
  </ButtonContainer>), [
    classes.blankButton,
    onClick2,
    onClick5,
    t.goBack,
    t.verifyNumber,
    isMobile,
  ])

  const authenticatorBottomButtons = useMemo(() => (<ButtonContainer
    align={isMobile ? 'center' : 'right'}
    maxWidth
  >
    <BlankButton
      color='danger'
      maxWidth
      onClick={onClick2}
    >
      { t.goBack }
    </BlankButton>
    <SolidButton
      disabled={verificationLoading}
      maxWidth
      mutationLoading={verificationLoading}
      onClick={handleClickVerify}
      testID='OneTimePasswordForm-Ftui-submit'
    >
      { t.verifyAuthenticator }
    </SolidButton>
  </ButtonContainer>), [
    handleClickVerify,
    onClick2,
    t,
    verificationLoading,
    isMobile,
  ])

  const submitOtpButtons = useMemo(() => (<ButtonContainer
    align={isMobile ? 'center' : 'right'}
    maxWidth
  >
    <BlankButton
      color='danger'
      onClick={onClick3}
    >
      { t.goBack }
    </BlankButton>
    <SolidButton
      disabled={verificationLoading}
      mutationLoading={verificationLoading}
      onClick={handleClickVerify}
      testID='OneTimePasswordForm-Ftui-submit'
    >
      { t.submit }
    </SolidButton>
  </ButtonContainer>), [
    handleClickVerify,
    onClick3,
    t.goBack,
    t.submit,
    verificationLoading,
    isMobile,
  ])

  const showOTPModal = useCallback(() => showModal('OtpSecretModal'), [showModal])

  const addVerificationCard = useMemo(() => selectedVerificationMethod === VERIFICATION_METHOD_PHONE
    ? (
      <Card
        bottomButtons={verifyBottomButtons}
        title={title3}
      >
        <Body>
          { t.addPhoneBody }
        </Body>
        <Spacer />
        <PhoneNumberInput
          data-test-id='OneTimePasswordForm-Ftui-phone'
          disableAreaCodes
          error={phoneError}
          fullWidth
          onChange={onChangePhone}
          value={formValues.phone}
        />
      </Card>)
    : (
      <Card
        bottomButtons={authenticatorBottomButtons}
        title={title3}
      >
        <Grid spacing={SPACING_HALF}>
          <Col>
            <Body>
              { t.scanCodeBody }
            </Body>
          </Col>
          <Col>
            <Grid spacing={SPACING_ZERO}>
              <Col
                sm={4}
                xs={2}
              />
              <Col
                sm={4}
                xs={8}
              >
                <OtpQrCode />
              </Col>
              <Col
                sm={4}
                xs={2}
              />
            </Grid>
            <Flexbox align='center'>
              {
                data?.otpAuthSecret && <HyperLink
                  onClick={showOTPModal}
                >
                  { t.cantScan }
                </HyperLink>
              }
            </Flexbox>
          </Col>
          <Col>
            <Body>
              { t.enterCodeBody }
            </Body>
          </Col>
          <Col>
            <OTPInput
              autoFocus // eslint-disable-line jsx-a11y/no-autofocus
              isNumberInput
              length={OTP_LENGTH}
              onChangeOTP={onChangeOTP}
              onPaste={handleClickVerify}
            />
          </Col>
        </Grid>
      </Card>), [
    selectedVerificationMethod,
    verifyBottomButtons,
    title3,
    t.addPhoneBody,
    t.scanCodeBody,
    t.cantScan,
    t.enterCodeBody,
    phoneError,
    onChangePhone,
    formValues.phone,
    authenticatorBottomButtons,
    data?.otpAuthSecret,
    showOTPModal,
    onChangeOTP,
    handleClickVerify,
  ])

  if (!data) return querySwitch

  return (<>
    { (!isNil(data) && data.otpMfa) || otpOnly
      ? <>
        { body
          ? <>
            <Spacer double />
            <Flexbox
              align='center'
              secondaryAlign='center'
            >
              <Heading4 variant='bold'>
                { body }
              </Heading4>
            </Flexbox>
          </>
          : undefined }
        <Spacer double />
        <Flexbox
          align='center'
          secondaryAlign='center'
        >
          { authMethod === VERIFICATION_METHOD_PHONE || (verifyOtp && data.otpMfaMethod === OtpMethodType.sms)
            ? <Body>
              { t.subtitle(phoneNumber ?? data.phoneNumber ?? '', forceSendInitialOtp) }
            </Body>
            : <Grid spacing={SPACING_HALF}>
              { verifyOtp
                ? null
                : <>
                  <Col>
                    <Body>
                      { t.scanCodeBody }
                    </Body>
                  </Col>
                  <Col>
                    <Grid spacing={SPACING_ZERO}>
                      <Col
                        sm={4}
                        xs={2}
                      />
                      <Col
                        sm={4}
                        xs={8}
                      >
                        <OtpQrCode />
                      </Col>
                      <Col
                        sm={4}
                        xs={2}
                      />
                    </Grid>
                    <Flexbox align='center'>
                      {
                        data.otpAuthSecret && <HyperLink
                          onClick={showOTPModal}
                        >
                          { t.cantScan }
                        </HyperLink>
                      }
                    </Flexbox>
                  </Col>
                </> }
              <Col>
                <Flexbox align='center'>
                  <Body>
                    { t.enterCodeBody }
                  </Body>
                </Flexbox>
              </Col>
            </Grid> }
        </Flexbox>
        <Spacer double />
        <OTPInput
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          isNumberInput
          length={OTP_LENGTH}
          onChangeOTP={onChangeOTP}
          onPaste={handleClickVerify}
        />
        <Spacer double />
        { forceSendInitialOtp && (authMethod === VERIFICATION_METHOD_PHONE || (verifyOtp && data.otpMfaMethod === OtpMethodType.sms))
          ? <>
            <Flexbox
              align='center'
              secondaryAlign='center'
            >
              <Body>
                { t.noCode }
              </Body>
            </Flexbox>
            <Spacer />
          </>
          : null }
        {
          rememberDevice
            ? <Flexbox
              align='center'
              secondaryAlign='center'
            >
              <CheckboxList
                items={checkBoxItems}
                onChange={onChangeRememberDevice}
                selectedValues={rememberDeviceValue}
                testID='OneTimePasswordForm-RememberDevice'
              />
            </Flexbox>
            : undefined
        }
        { authMethod === VERIFICATION_METHOD_PHONE || (verifyOtp && data.otpMfaMethod === OtpMethodType.sms)
          ? <Flexbox
            align='center'
            secondaryAlign='center'
          >
            <LinkButton
              className={classes.button}
              disabled={sendOtpLoading}
              mutationLoading={sendOtpLoading}
              onClick={onClick4}
              size='large'
              testID='OneTimePasswordForm-resend-link'
            >
              { forceSendInitialOtp ? t.resend : t.sendCode }
            </LinkButton>
          </Flexbox>
          : null }
        <Spacer double />
        <Flexbox
          align={isMobile ? 'center' : 'end'}
          reverse={isMobile}
          secondaryAlign='center'
          vertical={isMobile}
        >
          { showLogoutButton
            && <BlankButton
              className={cx({
                [classes.button]: !isMobile,
              })}
              color='danger'
              href='/session/logout'
              size='large'
              testID='OneTimePasswordForm-logout-button'
            >
              { t.logout }
            </BlankButton> }
          <SolidButton
            className={cx({
              [classes.button]: !isMobile,
            })}
            disabled={verificationLoading}
            mutationLoading={verificationLoading}
            onClick={handleClickVerify}
            size='large'
            testID='OneTimePasswordForm-verify-button'
          >
            { t.verify }
          </SolidButton>
        </Flexbox>
        <Spacer />
      </>
      : <Flexbox
        align='center'
        secondaryAlign='center'
      >
        <Grid>
          <Col>
            <Card
              hideDivider
              noLineMarginBottom
              title={title}
            />
          </Col>
          <Col>
            { step === VERIFICATION_METHOD_STEP
              ? <Card
                bottomButtons={bottomButtons}
                title={title2}
              >
                <AuthenticationMethodPicker
                  isAdmin={data.isAdmin}
                  onChangeVerificationMethod={onChangeVerificationMethod}
                  selectedVerificationMethod={[selectedVerificationMethod]}
                />
              </Card>
              : <Card
                hideDivider
                noLineMarginBottom
                title={title2}
              /> }
          </Col>
          <Col>
            { step < ADD_PHONE_NUMBER_STEP
              ? null
              : step > ADD_PHONE_NUMBER_STEP
                ? <Card
                  hideDivider
                  noLineMarginBottom
                  title={title3}
                />
                : addVerificationCard }
          </Col>
          <Col>
            { step === ENTER_CODE_STEP
              ? (
                <Card
                  bottomButtons={submitOtpButtons}
                  title={title4}
                >
                  <Flexbox
                    align='center'
                    secondaryAlign='center'
                  >
                    <Body>
                      { t.subtitle(data.phoneNumber ?? '', forceSendInitialOtp) }
                    </Body>
                  </Flexbox>
                  <Spacer />
                  <OTPInput
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    isNumberInput
                    length={OTP_LENGTH}
                    onChangeOTP={onChangeOTP}
                    onPaste={handleClickVerify}
                  />
                  <Spacer />
                  <Flexbox
                    align='center'
                    secondaryAlign='center'
                  >
                    <Body>
                      { t.noCode }
                      <HyperLink
                        // eslint-disable-next-line react-memo/require-usememo
                        onClick={() => handleClickResend(true)}
                        testID='OneTimePasswordForm-Ftui-resend-link'
                        variant={sendOtpLoading ? 'disabled' : 'default'}
                      >
                        { t.resendLink }
                      </HyperLink>
                    </Body>
                  </Flexbox>
                  <Spacer />
                  {
                    rememberDevice
                      ? <Flexbox
                        align='center'
                        secondaryAlign='center'
                      >
                        <CheckboxList
                          items={checkBoxItems}
                          onChange={onChangeRememberDevice}
                          selectedValues={rememberDeviceValue}
                          testID='OneTimePasswordForm-Ftui-RememberDevice'
                        />
                      </Flexbox>
                      : undefined
                  }
                </Card>)
              : null }
          </Col>
        </Grid>
      </Flexbox> }
    <SideModal
      modalKey='OtpSecretModal'
      onToggleClose={hideModal}
    >
      <Box mt={8}>
        <Heading3>
          { t.cantScan }
        </Heading3>
        <div>
          <Body>
            { t.cantScanDescription }
          </Body>
          <Spacer />
          <Box my={2}>
            <Body variant='bold'>
              { data.otpAuthSecret }
            </Body>
          </Box>
        </div>
        <Spacer />
        <ButtonContainer>
          <SolidButton
            onClick={hideModal}
          >
            { t.goBack }
          </SolidButton>
          <SolidButton
            color='secondary'
            copyable
            copyableText={data.otpAuthSecret ?? ''}
            icon='copy'
            ref={ref}
          >
            { t.copy }
          </SolidButton>
        </ButtonContainer>
      </Box>
    </SideModal>
  </>)
})

OneTimePasswordForm.displayName = 'OneTimePasswordForm'

export const ComponentWithProviders = memo<Props>((props) => (<Providers>
  <Card>
    <OneTimePasswordForm {...props} />
  </Card>
</Providers>))

ComponentWithProviders.displayName = 'OneTimePasswordForm'

export default OneTimePasswordForm
