import { memo } from 'react'
import Body from 'd2/components/Typography/Body'
import useStyles from './styles'

type Props = {
  message: string
}

const AppAnnouncementsBanner = memo<Props>(({
  message,
}) => {
  const { classes } = useStyles()
  return (
    <div
      className={classes.root}
      data-test-id='AppAnnouncementsBanner'
    >
      <Body
        className={classes.bannerText}
        variant='semibold'
      >
        { message }
      </Body>
    </div>
  )
})

AppAnnouncementsBanner.displayName = 'AppAnnouncementsBanner'

export default AppAnnouncementsBanner
