import { memo } from 'react'
// @ts-expect-error must convert module to ts
import PWAPrompt from 'react-ios-pwa-prompt'
import useTranslations from './translations'

// 10 because for some reason the Dashboard layout rerenders about
// 4 times and this PWAPrompt adds to localStorage each time it is rendered
const TIMES_TO_SHOW = 10

const IosPwaPrompt = memo<EO>(() => {
  const t = useTranslations()

  return (
    <PWAPrompt
      copyBody={t.body}
      copyClosePrompt={t.close}
      copyTitle={t.title}
      permanentlyHideOnDismiss={false}
      promptOnVisit={1}
      timesToShow={TIMES_TO_SHOW}
    />
  )
})

IosPwaPrompt.displayName = 'IosPwaPrompt'

export default IosPwaPrompt
