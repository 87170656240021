import { Outlet } from 'react-router-dom'
import { memo } from 'react'
import Dashboard from 'd2/layouts/Dashboard'
import SharedLayout from './SharedLayout'
import type { Props } from './types'

const ScreenLayout = memo<Props>(({ layoutProps, ...rest }) => (
  <SharedLayout
    Layout={Dashboard}
    layoutProps={layoutProps}
    {...rest}
  >
    <Outlet />
  </SharedLayout>
))

ScreenLayout.displayName = 'ScreenLayout'

export default ScreenLayout
