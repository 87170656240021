// NavigatePathRewrite is used to navigate to a dynamic route where the param is interpolated into the `to` path
//
// @example - Navigate to "/d2/deals/123/edit" when "/d2/advances/123/edit" is visited.
//   This is useful if the path changes over time. The old links will still work.
//
//   <Route
//     element={<NavigatePathRewrite
//       rewrite="advances"
//       to="deals"
//     />}
//     path='advances/*'
//   />

import { Navigate, NavigateProps } from 'react-router'
import { memo, useMemo } from 'react'
import { useLocation } from 'd2/hooks/useRouter'

// Replace `rewrite` path segment with the `to` prop.
const NavigatePathRewrite = memo(({ rewrite, to, ...props }: NavigateProps & { rewrite: string, to: string }) => {
  // find the path segment that matches `rewrite`. Replace it with `to`.
  const { pathname, search } = useLocation()

  const newTo = useMemo(() => {
    const pathSegments = pathname.split('/')
    const index = pathSegments.indexOf(rewrite)
    if (index === -1) {
      return pathname
    }
    pathSegments[index] = to
    return pathSegments.join('/') + search
  }, [
    pathname,
    search,
    rewrite,
    to,
  ])

  return (<Navigate
    to={newTo}
    {...props}
  />)
})

NavigatePathRewrite.displayName = 'NavigatePathRewrite'

export default NavigatePathRewrite
