import ready from '../utils/ready'

function init () {
  const showWootric = window.wootric && window.is_current_user_eligible_for_nps

  // FOR TESTING - Be sure to revert any changes in this section before making any commits

  // 1. Comment out this block in order to make the modal eligible to appear
  if (!showWootric) {
    return
  }

  // 2. Uncomment the line below to have the modal appear once the page loads
  // window.wootric_survey_immediately = true

  // 3. Set a value for WOOTRIC_TOKEN in `config/settings/development.rb` (get token from Prod settings)

  // END TESTING

  // Asynchronously load the beacon asset as a prerequisite for Wootric
  // Once beacon is loaded, then setup and execute the Wootric feature
  const user = window.current_user

  window.wootricSettings = {
    email: user.email, // The current logged in user's email address.
    created_at: Math.floor(Date.parse(user.created_at) / 1000).toString(), // User's account creation date
    account_token: window.VYDIA_SETTINGS.WOOTRIC_TOKEN, // The Vydia/Wootric account token (set this in `config/settings/development.rb` when testing)
    // Custom Properties
    properties: {
      currentTier_amount: (user.use_override_tier ? user.override_user_tier : user.current_tier) || 1000,
      lifetimeTier_amount: (user.use_override_tier ? user.override_user_tier : user.lifetime_tier) || 1000,
      tierIsEstimate: user.use_override_tier,
      fullName: user.full_name,
      organizationRole: user.role,
      url: `admin.vydia.com/admin/u/${user.id}`,
      userId: user.id,
    },
  }

  window.wootric('run')
}

ready(init)
