import { forwardProps } from 'd2/utils/props'
import { memo } from 'react'
import useStyles from './styles'
import type { Props } from './types'

const CardTile = memo<Props>(({
  align,
  children,
  className,
  fullHeight,
  paddingHorizontal,
  paddingVertical,
  title,
  ...props
}) => {
  const { classes, cx } = useStyles({
    align,
    paddingHorizontal,
    paddingVertical,
  })

  return (
    <div
      className={cx(classes.container, { [classes.fullHeight]: fullHeight }, className)}
      {...forwardProps(props)}
    >
      { title
        && <div className={classes.title}>
          { title }
        </div> }
      { children }
    </div>
  )
})

CardTile.displayName = 'CardTile'

export default CardTile
